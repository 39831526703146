import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const ShapeImage = styled.img`
  position: absolute;
  right: -10px;
  bottom: 1px;
  z-index: -1;

  ${media.md} {
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }

  ${media.xs} {
    max-width: 240px;
  }
`;

export const TriangleImage = styled.img`
  position: absolute;
  top: -8px;
  right: -35px;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: ${fontSize.md};
  line-height: 1.4;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 32px;

  ${media.sm} {
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  margin-bottom: 35px;

  ${media.sm} {
    font-size: 42px;
    line-height: 1.33;
    margin-bottom: 20px;
  }
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 16px;

  ${media.xs} {
    margin-left: 0;

    & > span {
      width: 100%;
    }
  }
`;

export const FormWrapper = styled.div`
  ${media.md} {
    margin-bottom: 60px;
  }
`;

export const FormProofs = styled.div`
  display: flex;
`;

export const Proof = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;

  ${media.xs} {
    font-size: 10px;
  }

  &::after {
    content: '';
    display: block;
    flex-shrink: 0;
    height: 2px;
    width: 2px;
    margin: 0 8px;
    background-color: black;

    ${media.xs} {
      margin: 0 5px;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;

export const FormLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
  }
`;

export const FormLabel = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #56556c;

  ${media.xxs} {
    font-size: 12px;
  }
`;

export const Form = styled.form`
  display: flex;
  margin-bottom: 22px;
  max-width: 530px;

  ${media.xs} {
    flex-direction: column;
  }

  & > div {
    height: 56px;
    width: 100%;

    ${media.xs} {
      margin-bottom: 30px;
    }
  }

  input {
    height: 100%;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 1.75;
  color: ${color.text.secondary};
  letter-spacing: 0.08em;
  margin-bottom: 13px;
  font-weight: 500;
  text-transform: uppercase;

  ${media.sm} {
    font-size: ${fontSize.xs};
  }
`;

export const Content = styled.div`
  display: flex;

  ${media.md} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  max-width: 595px;
  flex-shrink: 0;

  ${media.md} {
    max-width: 100%;
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.md} {
    justify-content: center;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 200px;
  margin-bottom: 85px;

  ${media.md} {
    padding-top: 120px;
    margin-bottom: 80px;
  }
`;

export const AnimationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${media.md} {
    justify-content: center;
    margin-bottom: 50px;
  }

  ${media.xs} {
    margin-bottom: 20px;
  }

  .illustration-wrapper {
    position: relative;
    height: 311px;
    width: 506px;

    ${media.sm} {
      width: 100%;
    }

    ${media.xs} {
      height: 260px;
      width: 320px;
    }
  }

  img {
    position: absolute;
  }

  .illustration-card {
    width: 65%;
  }

  .illustration-card-1 {
    top: 15%;
    left: -9%;

    ${media.xs} {
      left: -17%;
    }
  }

  .illustration-card-2 {
    top: -27%;
    left: 29%;
  }

  .illustration-card-3 {
    top: 70%;
    left: 18.4%;
  }

  .illustration-card-4 {
    top: 17%;
    right: -25%;
  }
`;

export const RightContent = styled.div`
  position: relative;
  width: 506px;
  max-width: 100%;

  ${media.lg} {
    width: 400px;
  }

  ${media.md} {
    width: 100%;
  }
`;
