import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const LogoWrapper = styled.div`
  &:last-child {
    margin-top: -20px;

    ${media.xxs} {
      margin-top: 0;
    }
  }

  .gatsby-image-wrapper {
    width: 150px !important;

    img {
      object-fit: contain !important;
    }
  }
`;

export const Wrapper = styled.section`
  margin-bottom: 40px;
`;

export const Container = styled(SharedContainer)``;

export const StyledTitle = styled(Title)`
  font-weight: normal;
  text-align: center;
  margin-bottom: 24px;

  ${media.sm} {
    line-height: 1.33;
    margin-bottom: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  column-gap: 30px;
  row-gap: 15px;

  & > div {
    flex-shrink: 0;
  }
`;
