import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { Link } from 'components/shared/link';
import { getFluidImage } from 'utils';

import {
  Wrapper,
  Container,
  LogoWrapper,
  ContentWrapper,
  StyledTitle,
  StyledButton,
} from './trusted.styles';

export const Trusted = ({ title, items, buttonText }) => {
  const logoItems = items.map(({ id, altText, localFile }) => {
    const fluid = getFluidImage(localFile);
    return (
      <LogoWrapper key={id}>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth / 2,
            height: fluid.presentationHeight / 2,
          }}
          fluid={fluid}
          alt={altText}
          loading="eager"
          key={id}
        />
      </LogoWrapper>
    );
  });

  return (
    <Wrapper>
      <Container>
        <StyledTitle as="h2" size="lg">
          {title}
        </StyledTitle>
        <ContentWrapper>{logoItems}</ContentWrapper>
        <StyledButton component={Link} to="#sectionBuyingGiftCards" arrow={false} theme="bordered">
          {buttonText}
        </StyledButton>
      </Container>
    </Wrapper>
  );
};
