import { Link } from 'gatsby';
import styled from 'styled-components';

import { media, color, fontSize, transition } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fbfbfc;

  ${media.md} {
    margin-bottom: 0;
  }

  ${media.xs} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const Container = styled(SharedContainer)``;

export const Grid = styled(SharedGrid)`
  margin: 0 20px;

  ${media.md} {
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: 30px 45px 0;
  border-radius: 16px;
  background-color: ${color.text.tertiary};
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 66px 150px -85px rgba(50, 50, 93, 0.5);

  ${media.lg} {
    padding: 50px 40px 0;
  }

  ${media.md} {
    padding: 50px 35px 0;
  }

  ${media.sm} {
    padding: 16px 35px 0;
  }
`;

export const ItemContent = styled.div``;

export const ItemHead = styled.div`
  margin-bottom: 5px;

  ${media.md} {
    display: flex;
    align-items: center;
  }
`;

export const ItemImg = styled.div`
  margin-bottom: 20px;

  ${media.md} {
    margin-right: 20px;
    margin-bottom: 0;
  }

  svg {
    ${media.sm} {
      width: 55px;
    }
  }
`;

export const ItemTitle = styled(Title)`
  font-size: ${fontSize.lg};
  line-height: 1.5;

  ${media.sm} {
    font-size: ${fontSize.base};
    line-height: 1.2;
  }
`;

export const ItemDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${color.text.secondary};
  margin: 0 0 60px;

  ${media.md} {
    margin: 0 0 30px;
  }

  br {
    ${media.md} {
      display: none;
    }
  }
`;

export const SellGiftCardsLinkText = styled.span`
  color: ${color.primary};
  margin-right: 20px;
`;

export const SellGiftCardsLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${fontSize.xs};
  font-weight: 500;
  text-decoration: none;
  color: ${color.primary};
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
  transition: ${transition.base};

  ${media.md} {
    margin-bottom: 55px;
  }

  ${media.sm} {
    margin-bottom: 42px;
  }
`;
