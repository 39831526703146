import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { color, media, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 50px;
`;

export const VideoContainer = styled(SharedContainer)`
  margin-bottom: 80px;

  @media (min-width: 1306px) {
    max-width: 1246px;
  }

  ${media.md} {
    margin-bottom: 0;
  }
`;

export const VideoItem = styled.div`
  display: block !important;
`;

export const VideoWrapper = styled.div`
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  z-index: 3;

  ${media.sm} {
    border-radius: 16px;
  }
`;

export const VideoImage = styled(GatsbyImage)`
  height: 450px;

  ${media.md} {
    height: 350px;
  }
  ${media.sm} {
    height: 202px;
  }
`;

export const VideoContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(244deg, rgba(15, 12, 59, 0.23), #2c295d);
  color: ${color.text.tertiary};
  overflow: hidden;
  text-align: center;
`;

export const VideoPlay = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background: ${color.text.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 32px;
  flex-shrink: 0;
  cursor: pointer;

  ${media.md} {
    width: 60px;
    height: 60px;
    margin-bottom: 34px;
  }

  ${media.sm} {
    margin: 0 0 22px;
    width: 57px;
    height: 57px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #e2fbff;
    ${media.md} {
      width: 26px;
      height: 26px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 0 11px 15px;
    border-color: transparent transparent transparent ${color.primary};
    margin-left: 5px;
    z-index: 1;

    ${media.md} {
      border-width: 5px 0 5px 8px;
      margin-left: 2px;
    }
  }
`;

export const VideoTitle = styled.div`
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 17px;

  ${media.md} {
    font-size: 24px;
  }

  ${media.sm} {
    font-size: ${fontSize.sm};
    margin-bottom: 9px;
  }
`;

export const VideoText = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;

  ${media.sm} {
    display: none;
  }
`;
