import styled from 'styled-components';

import { media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const LogoWrapper = styled.div`
  .gatsby-image-wrapper {
    width: 180px !important;

    img {
      object-fit: contain !important;
    }

    ${media.sm} {
      width: 150px !important;
    }

    ${media.xs} {
      width: 80px !important;
    }
  }
`;

export const Wrapper = styled.section`
  text-align: center;
  background-color: #2c295d;
  padding-top: 155px;
  padding-bottom: 155px;
  margin-bottom: 95px;

  ${media.md} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${media.xs} {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;

export const Container = styled(SharedContainer)``;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  color: ${color.text.tertiary};
  text-align: center;
  margin-bottom: 50px;

  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 20px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 226px;
  width: 100%;
  justify-content: center;
  border-color: ${color.text.tertiary};

  &:hover {
    border-color: ${color.primary};
  }

  & > span {
    color: ${color.text.tertiary} !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 55px;

  & > div {
    flex-shrink: 0;
  }

  ${media.sm} {
    row-gap: 15px;
    margin-bottom: 35px;
  }
`;
