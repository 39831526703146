import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  background-color: #f1f9fc;
  padding-bottom: 96px;

  ${media.sm} {
    margin-bottom: 1px;
  }
`;

export const Container = styled(SharedContainer)``;

export const Image = styled(GatsbyImage)`
  position: absolute !important;
  bottom: -150px;
  right: 0;

  img {
    object-fit: contain !important;
  }

  ${media.md} {
    position: relative !important;
    right: 0;
  }

  ${media.xs} {
    bottom: -80px;
    width: 263px !important;
    height: 349px !important;
  }

  /* parallax */
  transform: translateX(calc((var(--offset, 0)) * -35px));

  ${media.md} {
    transform: none;
  }
`;

export const ImageStatic = styled.img``;

export const ContainerFooter = styled(SharedContainer)`
  position: relative;
  border-radius: 16px;
  background-color: ${color.text.tertiary};
  box-shadow: 14px 56px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  @media (min-width: 1342px) {
    max-width: 1282px;
  }
  ${media.sm} {
    padding-bottom: 43px;
    box-shadow: none;
  }
`;

export const Grid = styled(SharedGrid)`
  background-color: #ffffff;
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 66px 150px -85px rgba(50, 50, 93, 0.5);
  border-radius: 16px;
  padding: 80px 90px;
  overflow: hidden;

  ${media.md} {
    padding: 50px 60px;
  }

  ${media.xs} {
    padding: 50px 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 32px;
  font-weight: normal;
  line-height: 1.41;
  margin-bottom: 73px;

  ${media.sm} {
    font-size: 23px;
    line-height: 1.6;
    margin-bottom: 55px;
  }

  br {
    ${media.lg} {
      display: none;
    }
  }
`;

export const DescriptionWrapper = styled.div``;

export const Description = styled.p`
  font-size: ${fontSize.xs};

  ${media.sm} {
    margin-top: 15px;
  }
`;

export const ContentLeft = styled(GridCell)``;

export const ContentLeftWrapper = styled(GridCell)``;

export const Bubble = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -30px;
    right: -20px;
    display: block;
    width: 390px;
    height: 390px;
    border-radius: 100%;
    background-color: #cceef9;

    ${media.md} {
      top: 80px;
      right: auto;
    }

    ${media.xs} {
      top: 40px;
      width: 327px;
      height: 327px;
    }
    animation: 5s ease infinite alternate bubble;

    @keyframes bubble {
      0% {
        transform: scale(1) translateY(3px);
      }
      50% {
        transform: scale(1.025);
      }
    }
  }
`;

export const ContentRight = styled(GridCell)`
  position: relative;

  ${media.md} {
    display: flex;
    justify-content: center;
  }
`;
