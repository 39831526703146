import * as React from 'react';

import LogoDescriptionSvgUrl from 'assets/images/pages/home/drive/vodafone-dark.url.svg';
import { getTextWithoutParagraph } from 'utils';
import { useParallax } from 'utils/use-parallax';

import {
  Wrapper,
  Bubble,
  Container,
  Grid,
  ContentLeft,
  ContentLeftWrapper,
  ContentRight,
  StyledTitle,
  DescriptionWrapper,
  Description,
  Image,
  ImageStatic,
} from './partner.styles';

export const PartnerView = (props) => {
  const { quote, caption, imagePerson } = props;

  const { ref, offset } = useParallax();

  return (
    <Wrapper ref={ref} style={{ '--offset': offset }}>
      <Container>
        <Grid
          columns={2}
          gap={0}
          media={{
            md: { columns: 1 },
          }}
        >
          <ContentLeft>
            <ContentLeftWrapper>
              <StyledTitle
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(quote),
                }}
              />
              <DescriptionWrapper>
                <ImageStatic src={LogoDescriptionSvgUrl} alt="Vodafone" width={149} height={36} />
                <Description>{caption}</Description>
              </DescriptionWrapper>
            </ContentLeftWrapper>
          </ContentLeft>
          <ContentRight>
            <Bubble />
            <Image fixed={imagePerson} />
          </ContentRight>
        </Grid>
      </Container>
    </Wrapper>
  );
};
