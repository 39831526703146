import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/_settings/layout';
import { Collection } from 'components/blocks/collection-gift-cards/';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Audience } from 'components/pages/self-service/audience';
import { BuyingGiftCards } from 'components/pages/self-service/buying-gift-cards';
import { Comparison } from 'components/pages/self-service/comparison';
import { Hero } from 'components/pages/self-service/hero';
import { Logos } from 'components/pages/self-service/logos';
import { Partner } from 'components/pages/self-service/partner';
import { Recipients } from 'components/pages/self-service/recipients';
import { SendGiftCards } from 'components/pages/self-service/send-gift-cards';
import { Solutions } from 'components/pages/self-service/solutions';
import { Trusted } from 'components/pages/self-service/trusted';
import { Video } from 'components/pages/self-service/video';
import { buildPageMetadata } from 'utils/data-builders';

const SelfService = (props) => {
  const {
    data: { wpPage },
  } = props;
  const { acf } = wpPage;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      {acf.showHeader !== 'false' && <Header withLogoAndRightButtons withoutFirstButton />}
      <Hero
        label={acf.selfServiceHero.textAboveTitle}
        title={acf.selfServiceHero.title}
        description={acf.selfServiceHero.description}
        formLabel={acf.selfServiceHero.formLabel}
        formButtonText={acf.selfServiceHero.formButtonText}
        formSocialProofs={acf.selfServiceHero.formSocialProofs}
      />
      <Logos title={acf.selfServiceLogos.title} items={acf.selfServiceLogos.items} />
      <Solutions items={acf.selfServiceSolutions} />
      <Trusted
        title={acf.selfServiceTrusted.title}
        items={acf.selfServiceTrusted.logos}
        buttonText={acf.selfServiceTrusted.buttonText}
      />
      <SendGiftCards
        title={acf.selfServiceSendGiftCards.title}
        description={acf.selfServiceSendGiftCards.description}
        buttonText={acf.selfServiceSendGiftCards.buttonText}
      />
      <Recipients
        title={acf.selfServiceRecipients.title}
        description={acf.selfServiceRecipients.description}
        buttonText={acf.selfServiceRecipients.buttonText}
      />
      <Audience
        title={acf.selfServiceAudience.title}
        description={acf.selfServiceAudience.description}
        buttonText={acf.selfServiceAudience.buttonText}
      />
      <Video
        image={acf.video.acf.image}
        url={acf.video.acf.url}
        title={acf.video.acf.title}
        text={acf.video.acf.text}
      />
      <Collection
        title={acf.selfServiceCollectionGiftCards.title}
        description={acf.selfServiceCollectionGiftCards.description}
        withoutBgAndShadow
      />
      <Comparison
        title={acf.selfServiceComparison.title}
        description={acf.selfServiceComparison.description}
        items={acf.selfServiceComparison.items}
        footerText={acf.selfServiceComparison.footerText}
      />
      <Partner />
      <BuyingGiftCards
        title={acf.selfServiceBuyingGiftCards.title}
        textAboveButton={acf.selfServiceBuyingGiftCards.textAboveButton}
        buttonText={acf.selfServiceBuyingGiftCards.buttonText}
        proofs={acf.selfServiceBuyingGiftCards.proofs}
      />

      {acf.showFooter !== 'false' && <Footer theme="dark" withoutBorderTop withoutTopSection />}
    </Layout>
  );
};

export default SelfService;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      content
      acf {
        selfServiceHero {
          textAboveTitle
          title
          description
          formLabel
          formButtonText
          formSocialProofs {
            text
          }
        }
        selfServiceLogos {
          title
          items {
            altText
            localFile {
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        selfServiceSolutions {
          iconName
          title
          description
          buttonText
        }
        selfServiceTrusted {
          title
          logos {
            altText
            localFile {
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          buttonText
        }
        selfServiceSendGiftCards {
          title
          description
          buttonText
        }
        selfServiceRecipients {
          title
          description
          buttonText
        }
        selfServiceAudience {
          title
          description
          buttonText
        }
        video {
          ... on WpCustomersPageVideo {
            id
            acf {
              title
              text
              url
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        selfServiceCollectionGiftCards {
          title
          description
        }
        selfServiceComparison {
          title
          description
          items {
            text
            columnFirst {
              text
              checked
            }
            columnSecond {
              text
              checked
            }
          }
          footerText
        }

        selfServiceBuyingGiftCards {
          title
          textAboveButton
          buttonText
          proofs {
            text
          }
        }

        showHeader
        showFooter
      }
    }
  }
`;
