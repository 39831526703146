import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import CheckIconUrl from 'assets/images/check-icon.url.svg';
import { color, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Gradient = styled.div`
  position: relative;
  width: 564px;
  max-width: 100%;
  height: 593px;
  background: linear-gradient(167.61deg, #f1f9fc 9.01%, #f6f3ff 106.73%);
  border-radius: 20px;

  ${media.md} {
    height: 460px;
  }

  ${media.xs} {
    height: 350px;
  }
`;

export const Image = styled(GatsbyImage)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 115px;
  margin-left: 15px;
  max-width: 100%;
  width: 84%;

  ${media.lg} {
    width: 100%;
  }

  ${media.sm} {
    max-height: 130%;
  }

  ${media.xs} {
    margin-top: 85px;
  }

  img {
    object-fit: contain !important;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 24px;

  ${media.sm} {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;

      &::before {
        content: '';
        display: block;
        height: 9px;
        width: 12px;
        background-image: url('${CheckIconUrl}');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 10px;
        margin-right: 12px;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 24px;

  ${media.md} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ContentRight = styled.div`
  padding-left: 40px;

  ${media.md} {
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    align-items: center;

    ${media.sm} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 192px;

  ${media.md} {
    margin-bottom: 80px;
  }
`;
