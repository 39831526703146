import { Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import { Field } from 'components/shared/field';
import { FREE_EMAIL_PROVIDER_DOMAINS } from 'constants/free-email-provider-domains';

import {
  Wrapper,
  Inner,
  Content,
  Left,
  StyledTitle,
  LabelWrapper,
  Label,
  FormWrapper,
  Form,
  Proofs,
  Proof,
  StyledButton,
} from './buying-gift-cards.styles';
import LabelIcon from './images/label.inline.svg';

const CONTACT_FORM_ID = 23;
const { GATSBY_WP_API_BASE_URL } = process.env;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please, enter valid email')
    .required('Required')
    .test('is-whitelisted-email', 'This doesn’t appear to be a business email', (value) => {
      if (!value) return false;
      const [, emailDomain] = value.split('@');
      return !FREE_EMAIL_PROVIDER_DOMAINS.includes(emailDomain);
    }),
});

export const BuyingGiftCards = ({ label, title, textAboveButton, buttonText, proofs }) => (
  <Wrapper id="sectionBuyingGiftCards">
    <Content>
      <Inner>
        <Left>
          <Label>{label}</Label>
          <StyledTitle as="h1" size="xxl">
            {title}
          </StyledTitle>

          <FormWrapper>
            <LabelWrapper>
              <LabelIcon />
              <Label>{textAboveButton}</Label>
            </LabelWrapper>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const url = `${GATSBY_WP_API_BASE_URL}/wp-json/gf/v2/forms/${CONTACT_FORM_ID}/submissions`;
                  await fetch(url, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                      input_1: values.email,
                    }),
                  });

                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'wegift-form-submit',
                    formId: CONTACT_FORM_ID,
                  });

                  resetForm({ email: '' });
                } catch (error) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    type="email"
                    label={false}
                    autoComplete="email"
                    error={touched.email && errors.email}
                    placeholder="Your business email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledButton
                    type="submit"
                    theme="fill"
                    arrow={false}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    submitting={isSubmitting}
                  >
                    {buttonText}
                  </StyledButton>
                </Form>
              )}
            </Formik>
            <Proofs>
              {proofs.map(({ text }) => (
                <Proof>{text}</Proof>
              ))}
            </Proofs>
          </FormWrapper>
        </Left>
      </Inner>
    </Content>
  </Wrapper>
);
