import * as React from 'react';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import SolutionHrIcon from './icons/solution-hr.inline.svg';
import SolutionMarketingIcon from './icons/solution-marketing.inline.svg';
import SolutionProductIcon from './icons/solution-product.inline.svg';
import {
  Wrapper,
  Container,
  ContentWrapper,
  Grid,
  SellGiftCardsLink,
  SellGiftCardsLinkText,
  ItemWrapper,
  ItemContent,
  ItemHead,
  ItemImg,
  ItemTitle,
  ItemDescription,
} from './solutions.styles';

const iconCollection = {
  hr: SolutionHrIcon,
  marketing: SolutionMarketingIcon,
  product: SolutionProductIcon,
};

export const Solutions = ({ items }) => (
  <Wrapper>
    <Container>
      <ContentWrapper>
        <Grid
          columns={3}
          gap={24}
          media={{
            md: { columns: 1 },
          }}
        >
          {items.map(({ iconName, title, description, buttonText }) => {
            const Icon = iconCollection[iconName];
            return (
              <GridCell>
                <ItemWrapper>
                  <ItemContent>
                    <ItemHead>
                      <ItemImg>
                        <Icon />
                      </ItemImg>
                      <ItemTitle as="h3">{title}</ItemTitle>
                    </ItemHead>
                    <ItemDescription
                      dangerouslySetInnerHTML={{
                        __html: getTextWithoutParagraph(description),
                      }}
                    />
                  </ItemContent>
                  <SellGiftCardsLink to="#sectionBuyingGiftCards">
                    <SellGiftCardsLinkText color="blue">{buttonText}</SellGiftCardsLinkText>
                  </SellGiftCardsLink>
                </ItemWrapper>
              </GridCell>
            );
          })}
        </Grid>
      </ContentWrapper>
    </Container>
  </Wrapper>
);
