import * as React from 'react';

import { VideoModal } from 'components/blocks/video-modal';

import {
  Wrapper,
  VideoContainer,
  VideoWrapper,
  VideoItem,
  VideoImage,
  VideoContent,
  VideoPlay,
  VideoTitle,
  VideoText,
} from './video.styles';

export const Video = ({ image, url, title, text }) => {
  const [selectedVideoUrl, setSelectedVideoUrl] = React.useState(null);

  const handlePlayVideo = (url) => () => setSelectedVideoUrl(url);
  const handleModalClose = () => setSelectedVideoUrl(null);

  return (
    <Wrapper>
      <VideoContainer>
        <VideoWrapper>
          <VideoItem>
            <VideoImage
              alt={image.altText}
              fluid={{
                ...image.localFile.childImageSharp.fluid,
                aspectRatio: 1252 / 534,
              }}
            />
            <VideoContent>
              <VideoPlay onClick={handlePlayVideo(url)} />
              <VideoTitle>{title}</VideoTitle>
              <VideoText>{text}</VideoText>
            </VideoContent>
          </VideoItem>
        </VideoWrapper>
      </VideoContainer>
      {selectedVideoUrl && <VideoModal url={selectedVideoUrl} onClose={handleModalClose} />}
    </Wrapper>
  );
};
