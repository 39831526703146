import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { GridCell } from 'components/shared/grid';
import { Link } from 'components/shared/link';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  StyledButton,
  Description,
  Image,
  Gradient,
} from './send-gift-cards.styles';

export const SendGiftCards = ({ title, description, buttonText }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "pages/self-service/send-gift-cards/illustration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 512)
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Inner>
        <Grid
          columns={2}
          gap={20}
          media={{
            sm: { columns: 1, gap: 0 },
          }}
        >
          <GridCell>
            <ContentLeft>
              <StyledTitle
                as="h2"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(description),
                }}
              />
              <StyledButton
                component={Link}
                to="#sectionBuyingGiftCards"
                arrow={false}
                theme="fill"
              >
                {buttonText}
              </StyledButton>
            </ContentLeft>
          </GridCell>
          <GridCell>
            <ContentRight>
              <Gradient>
                <Image image={getImage(illustration)} alt="" />
              </Gradient>
            </ContentRight>
          </GridCell>
        </Grid>
      </Inner>
    </Wrapper>
  );
};
