import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { Wrapper, Container, LogoWrapper, ContentWrapper, StyledTitle } from './logos.styles';

export const Logos = ({ title, items }) => {
  const logoItems = items.map(({ id, altText, localFile }) => {
    const fluid = getFluidImage(localFile);
    return (
      <LogoWrapper key={id}>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth / 2,
            height: fluid.presentationHeight / 2,
          }}
          fluid={fluid}
          alt={altText}
          loading="eager"
          key={id}
        />
      </LogoWrapper>
    );
  });

  return (
    <Wrapper>
      <Container>
        <StyledTitle as="h3" size="xs">
          {title}
        </StyledTitle>
        <ContentWrapper>{logoItems}</ContentWrapper>
      </Container>
    </Wrapper>
  );
};
