import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Container = styled(SharedContainer)``;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 35px;

  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 20px;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.md};
  line-height: 1.5;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 95px;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  ${media.sm} {
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 50px;
  }
`;

export const WrapperScroll = styled.div`
  margin-bottom: 100px;

  ${media.md} {
    margin-bottom: 70px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }
  }

  ${media.sm} {
    margin-bottom: 50px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 830px;
  margin: 0 auto;
`;

export const ColumnFirst = styled.div`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #e3e5eb;
  }

  &::before {
    left: -46px;
  }

  &::after {
    right: 7px;
  }
`;

export const ColumnSecond = styled.div``;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 335px 1fr 1fr;
  column-gap: 40px;
  align-items: center;

  ${ColumnFirst}, ${ColumnSecond} {
    flex-shrink: 0;
    font-size: ${fontSize.sm};
    display: flex;
    align-items: center;
    color: #6b7696;
    padding: 16px 0;

    svg {
      margin-right: 12px;
    }
  }
`;

export const Text = styled.div`
  ${media.sm} {
    font-size: ${fontSize.sm};
  }
`;

export const FooterText = styled.span`
  display: block;
  text-align: center;
  font-size: ${fontSize.sm};
  color: #4b536a;
`;

export const Wrapper = styled.section`
  background-color: #f1f9fc;
  padding-top: 95px;
  padding-bottom: 95px;

  ${media.xs} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  ${StyledTitle}, ${Description}, ${ContentWrapper} {
    ${media.lg} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${media.md} {
      padding-left: 24px;
      padding-right: 24px;
    }

    ${media.sm} {
      padding-left: 18px;
      padding-right: 18px;
    }

    ${media.xs} {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;
