import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const FormWrapper = styled.div`
  ${media.md} {
    margin-bottom: 60px;
  }
`;
export const Form = styled.form`
  display: flex;
  margin-bottom: 22px;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;

  ${media.xs} {
    flex-direction: column;
  }

  & > div {
    height: 56px;
    width: 100%;

    ${media.xs} {
      margin-bottom: 30px;
    }
  }

  input {
    height: 100%;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  color: ${color.text.tertiary};
  text-align: center;
  margin-bottom: 55px;

  ${media.sm} {
    font-size: 42px;
    line-height: 1.33;
    margin-bottom: 35px;
  }
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 16px;

  ${media.xs} {
    margin-left: 0;

    & > span {
      width: 100%;
    }
  }
`;

export const Proofs = styled.div`
  display: flex;
  justify-content: center;
  color: ${color.text.tertiary};
`;

export const Proof = styled.span`
  display: flex;
  align-items: center;
  font-size: ${fontSize.xs};

  ${media.xs} {
    font-size: 10px;
  }

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 2px;
    margin: 0 8px;
    background-color: ${color.text.tertiary};

    ${media.xs} {
      margin: 0 5px;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  svg {
    margin-right: 8px;
  }
`;

export const Label = styled.span`
  font-size: ${fontSize.xs};
  line-height: 24px;
  color: ${color.text.tertiary};

  ${media.xxs} {
    font-size: 12px;
  }
`;

export const Content = styled.div`
  display: flex;

  ${media.md} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  max-width: 595px;
  flex-shrink: 0;

  ${media.md} {
    max-width: 100%;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  text-align: center;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 156px;
  padding-bottom: 156px;
  background-color: ${color.text.primary};

  ${media.md} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${media.xs} {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;
