import { Formik } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import * as Yup from 'yup';

import { Field } from 'components/shared/field';
import { FREE_EMAIL_PROVIDER_DOMAINS } from 'constants/free-email-provider-domains';

import {
  Wrapper,
  Inner,
  Content,
  Left,
  Right,
  Label,
  StyledTitle,
  FormWrapper,
  Form,
  FormLabelWrapper,
  FormLabel,
  FormProofs,
  Proof,
  Description,
  StyledButton,
  BackgroundImageWrapper,
  TriangleImage,
  ShapeImage,
  AnimationWrapper,
  RightContent,
} from './hero.styles';
import IllustrationCard1Url from './images/illustration-card-1.url.svg';
import IllustrationCard2Url from './images/illustration-card-2.url.svg';
import IllustrationCard3Url from './images/illustration-card-3.url.svg';
import IllustrationCard4Url from './images/illustration-card-4.url.svg';
import LabelIcon from './images/label.inline.svg';
import ShapeSvgUrl from './images/shape.url.svg';
import TriangleSvgUrl from './images/triangle.url.svg';

const CONTACT_FORM_ID = 23;
const { GATSBY_WP_API_BASE_URL } = process.env;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please, enter valid email')
    .required('Required')
    .test('is-whitelisted-email', 'This doesn’t appear to be a business email', (value) => {
      if (!value) return false;
      const [, emailDomain] = value.split('@');
      return !FREE_EMAIL_PROVIDER_DOMAINS.includes(emailDomain);
    }),
});

export const Hero = (props) => {
  const { label, title, description, formLabel, formButtonText, formSocialProofs } = props;

  // Animations
  const [animationContainer, animationContainerView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];
  const duration = 0.5;

  const transitionAction = {
    delay: 1,
    duration,
    ease: motionEase,
  };

  const variantsPositionY = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: custom,
    }),
  };

  const variantsPositionX = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      x: custom,
    }),
  };

  return (
    <Wrapper>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} aria-hidden />
      </BackgroundImageWrapper>
      <Inner>
        <Content>
          <Left>
            <Label>{label}</Label>
            <StyledTitle as="h1" size="xxl">
              {title}
            </StyledTitle>

            <Description>{description}</Description>
            <FormWrapper>
              <FormLabelWrapper>
                <LabelIcon />
                <FormLabel>{formLabel}</FormLabel>
              </FormLabelWrapper>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const url = `${GATSBY_WP_API_BASE_URL}/wp-json/gf/v2/forms/${CONTACT_FORM_ID}/submissions`;
                    await fetch(url, {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        input_1: values.email,
                      }),
                    });

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'wegift-form-submit',
                      formId: CONTACT_FORM_ID,
                    });

                    resetForm({ email: '' });
                  } catch (error) {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,

                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      name="email"
                      type="email"
                      label={false}
                      autoComplete="email"
                      error={touched.email && errors.email}
                      placeholder="Your business email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <StyledButton
                      type="submit"
                      theme="fill"
                      arrow={false}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      submitting={isSubmitting}
                    >
                      {formButtonText}
                    </StyledButton>
                  </Form>
                )}
              </Formik>

              <FormProofs>
                {formSocialProofs.map(({ text }) => (
                  <Proof>{text}</Proof>
                ))}
              </FormProofs>
            </FormWrapper>
          </Left>
          <Right>
            <RightContent>
              <ShapeImage src={ShapeSvgUrl} aria-hidden />
              <AnimationWrapper ref={animationContainer}>
                <motion.div
                  className="illustration-wrapper"
                  initial="hidden"
                  animate={animationContainerView && 'visible'}
                >
                  <motion.img
                    src={IllustrationCard1Url}
                    className="illustration-card illustration-card-1"
                    variants={variantsPositionX}
                    custom={70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard2Url}
                    className="illustration-card illustration-card-2"
                    variants={variantsPositionY}
                    custom={70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard3Url}
                    className="illustration-card illustration-card-3"
                    variants={variantsPositionY}
                    custom={-70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard4Url}
                    className="illustration-card illustration-card-4"
                    variants={variantsPositionX}
                    custom={-70}
                    transition={transitionAction}
                  />
                </motion.div>
              </AnimationWrapper>
            </RightContent>
          </Right>
        </Content>
      </Inner>
    </Wrapper>
  );
};
