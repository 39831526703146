import * as React from 'react';

import {
  Wrapper,
  WrapperScroll,
  ContentWrapper,
  StyledTitle,
  Description,
  Item,
  Text,
  ColumnFirst,
  ColumnSecond,
  FooterText,
} from './comparison.styles';
import CheckIcon from './images/check.inline.svg';
import UnCheckIcon from './images/uncheck.inline.svg';

export const Comparison = ({ title, description, items, footerText }) => (
  <Wrapper>
    <StyledTitle as="h2" size="lg">
      {title}
    </StyledTitle>
    <Description>{description}</Description>
    <WrapperScroll>
      <ContentWrapper>
        {items.map(({ text, columnFirst, columnSecond }) => {
          const IconColumnFirst = columnFirst.checked === 'true' ? CheckIcon : UnCheckIcon;
          const IconColumnSecond = columnSecond.checked === 'true' ? CheckIcon : UnCheckIcon;
          return (
            <Item>
              <Text>{text}</Text>
              <ColumnFirst>
                <IconColumnFirst />
                {columnFirst.text}
              </ColumnFirst>
              <ColumnSecond>
                <IconColumnSecond />
                {columnSecond.text}
              </ColumnSecond>
            </Item>
          );
        })}
      </ContentWrapper>
    </WrapperScroll>
    <FooterText>{footerText}</FooterText>
  </Wrapper>
);
